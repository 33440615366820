.datezonepicker-component {
  padding: 10px;
  box-sizing: border-box;
  border-radius: 6.19px;
  background-color: #FFFFFF;
  border: none;
  box-shadow: 0 2px 24px 0 #EDEDED;
  width: 400px;
  display: flex;
  flex-wrap: wrap;
}

.datezonepicker-day {
  border-radius: 30px;
  color: #222B45;
  font-family: Rubik;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 30px;
  height: 30px;
  width: 30px;
  display: inline-block;
  border: none;
  outline: none;
  font-weight: 400;
}

.datezonepicker-day:hover {
  background-color: rgba(103, 103, 103, 0.1) !important;
  color: #222B45 !important;
}

.datezonepicker-component .react-datepicker__month-container {
  float: none;
  width: calc(100% - 85px);
  order: 2;
}

.datezonepicker-component .react-datepicker__time-container {
  flex: 0 0 85px;
  max-width: 85px;
  order: 3;
}

.datezonepicker-component .react-datepicker__navigation--previous {
  top: 77px;
  left: 45%;
  margin-left: -100px;
  background: url(./static/chevron.svg) no-repeat;
  background-size: contain;
  border: none;
  background-size: contain;
  transform: rotate(90deg);
  width: 11px;
  height: 11px;
  outline: none;
}

.datezonepicker-component .react-datepicker__navigation--next {
  top: 77px;
  right: calc(45% + 85px) !important;
  margin-right: -100px;
  background: url(./static/chevron.svg) no-repeat;
  background-size: contain;
  border: none;
  background-size: contain;
  transform: rotate(-90deg);
  width: 11px;
  height: 11px;
  outline: none;
}