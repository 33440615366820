@import '~bootstrap/dist/css/bootstrap.css';
@import "~react-bootstrap-table-next/dist/react-bootstrap-table2.css";
@import "~react-custom-scroll/dist/customScroll.css";
@import "~react-quill/dist/quill.snow.css";
@import '~react-redux-toastr/lib/css/react-redux-toastr.min.css';

html {
  /*overflow-x: hidden;*/
}

body {
  margin: 0;
  font-family: 'Rubik', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input,
li,
button {
  font-family: 'Rubik', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input:-webkit-autofill
{
  -webkit-box-shadow: 0 0 0 30px #F5F6F8 inset !important;
  border: none!important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* report page, video section, right sidebar scrollbar  */
.rcs-custom-scroll .rcs-inner-handle {
  background-color: #F5F6F8 !important;
}

.MuiAutocomplete-popper .MuiAutocomplete-paper {
  font-size: 12px;
  font-weight: 500;
}

.slick-slider, .slick-list, .slick-track {
  height: 100%;
}

.slick-slide > div {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
}

body.noscroll {
  overflow: hidden;
}

@media (max-width: 900px) {
  body {
    min-width: 100px;
  }
}
