.datapicker-component {
  padding: 26px;
  box-sizing: border-box;
  border-radius: 6.19px;
  background-color: #FFFFFF;
  border: none;
  box-shadow: 0 2px 24px 0 #EDEDED;
  width: 100%;
}

.react-datepicker-popper {
  z-index: 999;
  width: 100%;
}

.datapicker-day {
  border-radius: 30px;
  color: #222B45;
  font-family: Rubik;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 30px;
  height: 30px;
  width: 30px;
  display: inline-block;
  border: none;
  outline: none;
  font-weight: 400;
}

.datapicker-day:hover {
  background-color: rgba(103,103,103,0.1) !important;
  color: #222B45 !important;
}

.react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover {
  border-radius: 30px;
}

.react-datepicker__day--keyboard-selected, .react-datepicker__day--selected {
  background-color: rgba(0,149,255,0.15);
  color: white;
}

.react-datepicker__day--highlighted, .react-datepicker__month-text--highlighted, .react-datepicker__quarter-text--highlighted, .react-datepicker__year-text--highlighted {
  background-color: #0095FF;
  color: white;
}

.react-datepicker__day--disabled {
  opacity: 0.75;
  color: #8F9BB3;
  background-color: transparent;
}

.react-datepicker__month-container {
  float: none;
}

.react-datepicker__month {
  margin: 0 0 12px 0;
}

.react-datepicker__header {
  background-color: transparent;
  border-bottom: none;
}

.react-datepicker__day-names, .react-datepicker__week {
  display: flex;
  justify-content: space-between;
}

.react-datepicker__navigation--previous {
  top: 40px;
  left: 50%;
  margin-left: -100px;
  background: url(./static/chevron.svg) no-repeat;
  background-size: contain;
  border: none;
  background-size: contain;
  transform: rotate(90deg);
  width: 11px;
  height: 11px;
  outline: none;
}

.react-datepicker__navigation--next {
  top: 40px;
  right: 50%;
  margin-right: -100px;
  background: url(./static/chevron.svg) no-repeat;
  background-size: contain;
  border: none;
  background-size: contain;
  transform: rotate(-90deg);
  width: 11px;
  height: 11px;
  outline: none;
}

.react-datepicker__current-month {
  color: #222B45;
  font-family: Rubik;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 6px;
}

.react-datepicker__day-name {
  color: #222B45;
  font-family: Rubik;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 24px;
  font-weight: 500;
}

.react-datepicker__triangle {
  display: none;
}
